import React, { useEffect, useState } from 'react'
import { Stack, TextField, Button, Typography, Box } from '@mui/material';
import useWindowDimensions from '../Screens/useWindowDimensions.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../Screens/recordButton.css'
import axios from '../Login/axiosConfig'
import { convertDomainToValidURL } from '../Helpers/Helpers';



const CreateSurvey = ({
    setShowMessages,
    handleScreenChangeClick,
    openLogin,
    isAuthenticated

}) => {

   


    const { height, width } = useWindowDimensions();
    const [researchObjective, setResearchObjective] = useState("")
    const [companyUrl, setCompanyUrl] = useState("")


    const createStory = (dataObj) => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Setup a StorySnacks Account",
                message: `Please log in or create an account to continue.`,
            })
            openLogin()
            return
        }

        if (dataObj.researchObjective.length < 50) {
            setShowMessages({
                alertType: "error",
                title: "More Details Required",
                message: `Add more messaging details before continuing. Two or more sentences is recommended.`,
            })
            return
        }

        let cleanUrl = ""
        if (dataObj.companyUrl != "") {
          cleanUrl = convertDomainToValidURL(dataObj.companyUrl)
          if (cleanUrl === "badUrl") {
            setShowMessages({
              alertType: "error",
              title: "Website URL issue",
              message: "Please check your site's URL to make sure it's formatted correctly. Example: https://www.datasnack.ai/",
            })
            return
          }
  
        }
        dataObj.companyUrl = cleanUrl


        setShowMessages({
            alertType: "info",
            timeInSecs: 120,
            showProgress: true,
            title: "Processing Survey...",
            message: `Creating survey and analyzing questions. This may take a few minutes.`,
        })
        axios.post(`/api/v1/runSurvey`, dataObj)
            .then(response => {
                setShowMessages(null)
                handleScreenChangeClick({
                    selectedIndex: `SurveyView`,
                    surveyCard: response.data

                })

            }).catch(error => {
                // need to figure out way to handle userFull swiping away from screen and waiting
                setShowMessages(null)
                setShowMessages({
                    alertType: "error",
                    title: "Problem Creating Survey",
                    message: "Please try again later",
                })
                console.log("runSurvey error:", error)

            })
    }



    useEffect(() => {
        console.log("in Create Survey")
        window.scrollTo(0, 0)

    }, [])

   

    return (

        <Box style={{
            backgroundImage: `linear-gradient(to bottom, #212325 50%, #0f0f0f 100%)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: height,


        }}>

            {/* Back button */}
            <Stack
                direction={"row"}
                paddingX={window.$isMobile ? 2.5 : 5}
                paddingTop={2}
                // alignItems={"flex-end"}
                justifyContent={"flex-start"}>
                <ArrowBackIcon
                    onClick={() => {
                        console.log("HomePage")
                        handleScreenChangeClick({
                            selectedIndex: "HomePage"
                        })
                    }}
                    sx={{
                        fontSize: window.$isMobile ? 18 : 18, cursor: "pointer", color: "#fcfdfd",
                        opacity: 0.3
                    }} />


            </Stack>
            {/* Content Block */}
            <Stack

                paddingX={window.$isMobile ? 2.5 : "20%"}
                style={{
                    display: "flex",
                    // minHeight: height,
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "flex-start",

                    // width: "100%"
                }}
                direction="column"
                justifyContent={"center"} alignItems={"flex-start"}>

                <Typography
                    paddingTop={4}
                    // paddingBottom={2}
                    variant={'h3'}
                    sx={{

                        textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 600 },
                        lineHeight: window.$isMobile ? '100%' : '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Create Survey
                </Typography>
                

            </Stack>

            {/* Inputs  */}

            <Stack width={"100%"}
                paddingX={window.$isMobile ? 2.5 : "20%"}
                style={{
                    display: "flex",
                    // minHeight: height,
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "flex-start",

                    // width: "100%"
                }}
                marginTop={5} spacing={4}>




                <Stack>
                    <Typography
                        variant={'h7'}
                        sx={{ paddingLeft: 1, paddingRight: "10%", marginBottom: 2, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '120%', color: "#e5f0f8" }} fontStyle={"normal"} >
                        Please share some details about the messaging you want the survey to cover. (Two or more sentences is recommended.)
                    </Typography>
                    <TextField
                        autoFocus={false}

                        onChange={(e) => {
                            setResearchObjective(e.target.value)
                        }}
                        id="researchObjective"
                        label="Messaging to Analyze"
                        type="text"
                        focused
                        fullWidth
                        multiline
                        required
                        value={researchObjective}
                        variant={'outlined'}
                        margin={"none"}
                        sx={textBoxDesign}
                    />
                </Stack>

                <Stack>
                    <Typography
                        variant={'h7'}
                        sx={{ paddingLeft: 1, paddingRight: "10%", marginBottom: 2, typography: { fontFamily: "Inter", fontWeight: 600 }, lineHeight: '120%', color: "#e5f0f8" }} fontStyle={"normal"} >
                        Add company website for greater insight. (Optional)
                    </Typography>
                    <TextField
                        autoFocus={false}

                        onChange={(e) => {
                            setCompanyUrl(e.target.value)
                        }}
                        id="appearance"
                        label="Company Website"
                        type="text"
                        fullWidth
                        focused
                        value={companyUrl}
                        variant={'outlined'}
                        margin={"none"}
                        sx={textBoxDesign}
                    />
                </Stack>
             
             



                <Stack

                    paddingTop={5}
                    spacing={3}
                    width={"100%"}
                    direction={"row"}

                    justifyContent={"center"}
                >
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setResearchObjective("")
                            setCompanyUrl("")
    
                        }}

                        sx={{
                            borderColor: "#ffffff",
                            width: window.$isMobile ? "100%" : "75%"
                        }}

                    >
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            Clear

                        </Typography>
                    </Button>


                    <Button
                        variant="contained"
                        onClick={() => {
                            createStory({
                                researchObjective,
                                companyUrl
                            })
                        }}

                        sx={{
                            backgroundColor: "#e8454f",
                            width: window.$isMobile ? "100%" : "75%"
                        }}

                    >
                        <Typography
                            variant={'body1'}
                            sx={{

                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            Run Survey

                        </Typography>
                    </Button>
                </Stack>

                <Stack marginTop={3}>

                </Stack>


            </Stack>


            <Stack
                paddingBottom={10}
            />


        </Box>






    )

}



export default CreateSurvey


const textBoxDesign = {
    width: "100%",
    '& label.Mui-focused': {
        color: '#ffffff',

    },
    '& .MuiFormLabel-root': {
        color: '#ffffff',
        opacity: 0.9,
    },
    '& .MuiInputBase-root': {
        color: "#ffffff",
        borderRadius: 1,
        typography: { fontFamily: "Inter", fontWeight: 400 }, fontSize: 16,
        '& .MuiOutlinedInput-root': {
            color: "#ffffff",

        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#006ec2",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#006ec2",
            borderWidth: "1px"
        }
    }


}

