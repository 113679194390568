import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material';

const Typewriter = ({ origText, textColor, setUpdateLine }) => {
    const [allText, setAllText] = useState("");
   
  

    useEffect(() => {
        
        setUpdateLine(0)
        let index = 0
        let textAccumulator = ""
        console.log("use effect called",textAccumulator)
        let textInterval = setInterval(()=>{
            textAccumulator = textAccumulator + origText[index];
            setAllText(textAccumulator);
            index++
            
            if (index === origText.length) {
                setUpdateLine(-1)
               clearInterval(textInterval)
            }

            if (index % 20 === 0) {
                setUpdateLine(index)
            }
        }, 5)

        return ()=> {
            clearInterval(textInterval)
        }
    }, []);

    return (
       
        <Typography
            marginBottom={2}
            sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                textAlign: "left", typography: { fontFamily: "Inter" },
                fontSize: 16, lineHeight: '105%', color: textColor ?? "#86c696"
            }} fontStyle={"normal"}>
            {allText}

        </Typography>
       
       
    );
};

export default Typewriter;