import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddCompanyPopUp({setCompanyInfo}) {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            setCompanyInfo(formJson);
            
            console.log(formJson);
            handleClose();
          },
        }}
      >
        <DialogTitle>Company Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
          To tailor DataSnack's results specifically to your needs, please provide your website and any relevant details about your company.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="companyUrl"
            label="Company Website"
            type="url"
            fullWidth
            variant="standard"
          />
           <TextField
            autoFocus
            margin="dense"
            id="details"
            name="companyDetails"
            label="Company Details"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Add Details</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
