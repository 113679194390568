import React, { useState, useEffect } from 'react'
import axios from '../Login/axiosConfig'
import { FormLabel, Radio, FormControl, RadioGroup, FormControlLabel, Checkbox, TextField, Button, Stack, Typography } from '@mui/material';


const Feedback = ({ handleScreenChangeClick, setShowMessages }) => {
    const [recommend, setRecommend] = useState("");
    const [satisfied, setSatisfied] = useState("")
    const [describe, setDescribe] = useState([])
    const [needs, setNeeds] = useState("")
    const [futureFunctionality, setFutureFunctionality] = useState("")
    const [purchase, setPurchase] = useState("")
    const [comments, setComments] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
        return () => {
            setShowMessages(null)
          };
    }, []);


    const handleSubmit = () => {


        const feedback = {
            recommend: recommend,
            satisfied: satisfied,
            describe: describe,
            needs: needs,
            futureFunctionality: futureFunctionality,
            purchase: purchase,
            comments: comments
        }
        console.log("data from submit", feedback)

        axios.post('/api/v1/addFeedback', feedback)
            .then(response => {
                console.log(response.data)
               
                setShowMessages({
                    alertType: "info",
                    title: "Thank you!",
                    message: "Your feedback has been received!",
                  
                })
                setTimeout(() => {
                    handleScreenChangeClick({
                        selectedIndex: "HomePage"
                    })
                }, 2000)

            }).catch(error => {

                console.log("error", error)
                setShowMessages({
                    alertType: "error",
                    title: "Problem Processing Feedback",
                    message: "Please try again at a later time.",
        
                })

            });
    }

    return (
        <>
            <div style={{
                backgroundColor: "#F5F7F8",
                minHeight: "1024px",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
            }}>

                <div style={{
                    display: "flex", justifyContent: "space-between", flexDirection: "column",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingTop: "15px",
                    paddingBottom: "15px",

                }}>
                    <Stack width={"100%"} direction="column" marginTop={5} >
                        <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#212325" }} fontStyle={"normal"} component="div">
                            Feedback
                        </Typography>
                        <Stack marginTop={3}>
                            <FormControl>
                                <FormLabel id="demo-radio-buttons-group-label">How likely is it that you would recommend this product to a friend or colleague?</FormLabel>
                                <RadioGroup
                                    onChange={(e) => {
                                        setRecommend(e.target.value)
                                    }}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="Not at all likely"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value={"Not at all likely"} control={<Radio size="small" />} label="Not at all likely" />
                                    <FormControlLabel value={"Not so Likely"} control={<Radio size="small" />} label="Not so Likely" />
                                    <FormControlLabel value={"Somewhat likely"} control={<Radio size="small" />} label="Somewhat likely" />
                                    <FormControlLabel value={"Very likely"} control={<Radio size="small" />} label="Very likely" />
                                    <FormControlLabel value={"Extreme likely"} control={<Radio size="small" />} label="Extreme likely" />

                                </RadioGroup>
                                <br></br>
                                <FormLabel id="demo-radio-buttons-group-label">Overall, how satisfied or dissatisfied are you with our product?
                                </FormLabel>
                                <RadioGroup
                                    onChange={(e) => {
                                        setSatisfied(e.target.value)
                                    }}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="Neither satisfied nor dissatisfied"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value={"Very satisfied"} control={<Radio size="small" />} label="Very satisfied" />
                                    <FormControlLabel value={"Somewhat satisfied"} control={<Radio size="small" />} label="Somewhat satisfied" />
                                    <FormControlLabel value={"Neither satisfied nor dissatisfied"} control={<Radio size="small" />} label="Neither satisfied nor dissatisfied" />
                                    <FormControlLabel value={"Somewhat dissatisfied"} control={<Radio size="small" />} label="Somewhat dissatisfied" />
                                    <FormControlLabel value={"Very dissatisfied"} control={<Radio size="small" />} label="Very dissatisfied" />


                                </RadioGroup>

                                <br></br>
                                <FormLabel id="demo-radio-buttons-group-label">Which of the following words would you use to describe our products? Select all that apply.
                                </FormLabel>
                                <RadioGroup
                                    onChange={(e) => {
                                        setDescribe(e.target.value)
                                    }}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="Not at all likely"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("Reliable")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="Reliable" />} label="Reliable" />
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("High quality")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="High quality" />} label="High quality" />
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("Useful")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="Useful" />} label="Useful" />
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("Unique")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="Unique" />} label="Unique" />
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("Impractical")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="Impractical" />} label="Impractical" />
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("Ineffective")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="Ineffective" />} label="Ineffective" />
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("Poor quality")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="Poor quality" />} label="Poor quality" />
                                    <FormControlLabel control={<Checkbox onChange={() => {
                                        let array = describe
                                        array.push("Unreliable")
                                        setDescribe(array)
                                        console.log(array)
                                    }} name="Unreliable" />} label="Unreliable" />

                                </RadioGroup>

                                <br></br>
                                <FormLabel id="demo-radio-buttons-group-label">How well does our product meet your needs?
                                </FormLabel>
                                <RadioGroup
                                    onChange={(e) => {
                                        setNeeds(e.target.value)
                                    }}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="Neither satisfied nor dissatisfied"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value={"Extremely well"} control={<Radio size="small" />} label="Extremely well" />
                                    <FormControlLabel value={"Very well"} control={<Radio size="small" />} label="Very well" />
                                    <FormControlLabel value={"Somewhat well"} control={<Radio size="small" />} label="Somewhat well" />
                                    <FormControlLabel value={"Not so well"} control={<Radio size="small" />} label="Not so well" />
                                    <FormControlLabel value={"Not at all well"} control={<Radio size="small" />} label="Not at all well" />


                                </RadioGroup>

                                {/* <br></br>
                                <FormLabel id="demo-radio-buttons-group-label">How well does our product meet your needs?
                                </FormLabel>
                                <RadioGroup
                                    onChange={(e) => {
                                        setNeeds(e.target.value)
                                    }}
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    // defaultValue="Neither satisfied nor dissatisfied"
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel value={"Extremely well"} control={<Radio size="small" />} label="Extremely well" />
                                    <FormControlLabel value={"Very well"} control={<Radio size="small" />} label="Very well" />
                                    <FormControlLabel value={"Somewhat well"} control={<Radio size="small" />} label="Somewhat well" />
                                    <FormControlLabel value={"Not so well"} control={<Radio size="small" />} label="Not so well" />
                                    <FormControlLabel value={"Not at all well"} control={<Radio size="small" />} label="Not at all well" />


                                </RadioGroup> */}



                                <br></br>
                               
                                <TextField
                                    autoFocus={false}
                                    margin="dense"
                                    multiline
                                    onChange={(e) => {
                                        setFutureFunctionality(e.target.value)
                                    }}
                                    id="future"
                                    label="What functionality would you like to see added to the product in the future?"
                                    type="text"
                                    fullWidth
                                    value={futureFunctionality}
                                    variant="standard"
                                />

                                <br></br>
                                <TextField
                                    autoFocus={false}
                                    margin="dense"
                                    onChange={(e) => {
                                        setComments(e.target.value)
                                    }}
                                    id="comments"
                                    label="Any additional comments, questions, or concerns?"
                                    type="text"
                                    multiline
                                    fullWidth
                                    value={comments}
                                    variant="standard"
                                />

                            </FormControl>





                            <Stack marginBottom={5} width={"100%"} direction="row" spacing={2} marginTop={4} justifyContent={"flex-end"} >

                                <Button onClick={() => {
                                    let toSend = {
                                        selectedIndex: "Dashboard",
                                    }
                                    handleScreenChangeClick(toSend)
                                }}>Cancel</Button>
                                <Button onClick={handleSubmit} >Submit</Button>
                            </Stack>

                        </Stack>

                    </Stack>
                </div>

            </div>
        </>
    )

}

export default Feedback