import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { Chip, Typography, Stack, InputAdornment, IconButton, TextField, Button, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import SendIcon from '@mui/icons-material/Send'
import useWindowDimensions from './useWindowDimensions';
import Typewriter from '../Components/Typewriter';
import { v4 as uuidv4 } from 'uuid';
import ServerBotView from './ServerBotView';


const SurveyView = ({
    serverBotData,
    handleServerBotInput,
    setShowMessages,
    handleScreenChangeClick }) => {
    const scrollRef = useRef(null);
    const inputRef = useRef(null)
    let workInterval = useRef(null)



    const [updateLine, setUpdateLine] = useState(0)
    const [activeChip, setActiveChip] = useState(null)
    const [userInput, setUserInput] = useState("")
    const [shouldClearText, setShouldClearText] = useState(false)
    const { height, width } = useWindowDimensions();
    const [chatMessages, setChatMessages] = useState(null)
    const [digitalCloneId, setDigitalCloneId] = useState(null)



    useEffect(() => {
        if (serverBotData === null || serverBotData === undefined) {
            return
        }
        console.log("serverBotData", serverBotData)

        setActiveChip("group")
        setDigitalCloneId("group")
    }, [serverBotData]);


    return (
        <div >



            <Stack
                paddingX={2.5}
                paddingY={5}
            >

                <Stack sx={{
                    position: "relative",
                    display: "inline-block"
                }}>
                    <img
                        src={serverBotData.survey.imageUrl}
                        onError={() => {
                            handleScreenChangeClick({
                                updateDash: true,
                            })
                        }}
                        alt="Location Image"
                        style={{ width: "100%", objectFit: "cover", maxHeight: 350, borderRadius: 10 }} />





                </Stack>

                <Typography

                    paddingTop={2}
                    variant={'h4'}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    {serverBotData.survey.title}
                </Typography>
                <Typography
                    variant={'body1'}
                    paddingTop={1}

                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 600 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    {serverBotData.survey.subtitle}
                </Typography>
                <Typography
                    variant={'h7'}
                    paddingTop={2}
                    marginBottom={3}
                    paddingX={window.$isMobile ? 0 : 20}

                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 5,
                        whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 400 },
                        lineHeight: '100%',
                        color: "#ebebeb"
                    }} fontStyle={"normal"}>
                    {serverBotData.survey.description}
                </Typography>


                <Typography
                    variant={'h6'}
                    paddingTop={2}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Highlights
                </Typography>

                <Stack spacing={1}
                    paddingTop={3}
                    // marginBottom={window.$isMobile ? 0 : 4}
                    width={"100%"}
                    sx={{ maxHeight: 600, overflowX: 'scroll' }}
                    justifyContent={"center"}
                    direction="row"
                    useFlexGap flexWrap="wrap">
                    {serverBotData && serverBotData.survey.topics.map((e) => (
                        <Chip
                            variant={'filled'}
                            key={e}
                            label={e}



                            sx={{
                                backgroundColor: '#2d2d2d',
                                padding: 1,
                                fontSize: 16,
                                maxWidth: width * .8,
                                typography: { fontFamily: "Inter" },
                                textTransform: "none", color: "#ffffff",

                            }}
                            onClick={() => {
                                console.log("clicked chip")

                            }} />
                    ))}

                </Stack>



            </Stack>


            <Stack paddingX={2.5}>
                <ServerBotView
                    serverBotData={serverBotData}
                    setShowMessages={setShowMessages}
                />
            </Stack>
        </div>
    )

}

export default SurveyView







