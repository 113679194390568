import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { ListItem, ListItemText, Divider, TextField, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';
import { convertDomainToValidURL } from '../Helpers/Helpers';


const Contact = ({ handleScreenChangeClick, setShowMessages, handleEmailSubscribe }) => {

    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [note, setNote] = useState("")
    const [email, setEmail] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);


  

    return (
        <>
            <div style={{
                backgroundColor: "#F5F7F8",
                minHeight: "1024px",
                // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23262f71' fill-opacity='0.05'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`
            }}>

                <div style={{
                    display: "flex", justifyContent: "space-between", flexDirection: "column",
                    paddingLeft: "5%",
                    paddingRight: "5%",
                    paddingTop: "15px",
                    paddingBottom: "15px",

                }}>
                    <Stack width={"100%"} direction="column" marginTop={5} >
                        <Typography marginTop={0} sx={{ fontSize: 26, typography: { fontFamily: "Roboto", fontWeight: 900 }, lineHeight: '90%', color: "#37383a" }} fontStyle={"normal"} component="div">
                            Contact Us
                        </Typography>
                        <Stack marginTop={3}>

                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                id="name"
                                label="Name"
                                type="text"
                                fullWidth
                                value={name}
                                variant="standard"
                            />

                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setEmail(e.target.value)
                                }}
                                id="email"
                                label="Email"
                                type="email"
                                fullWidth
                                value={email}
                                variant="standard"
                            />
                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    setCompany(e.target.value)
                                }}
                                id="company"
                                label="Company"
                                type="text"
                                value={company}
                                fullWidth
                                variant="standard"
                            />
                            <TextField
                                autoFocus={false}
                                margin="dense"
                                onChange={(e) => {
                                    // add validation
                                    setNote(e.target.value)
                                }}
                                multiline
                                id="note"
                                label="Message"
                                type="text"
                                fullWidth
                                value={note}
                                variant="standard"
                            />




                            <Stack width={"100%"} direction="row" spacing={2} marginTop={2} justifyContent={"flex-end"} >

                                <Button onClick={() => {
                                    let toSend = {
                                        selectedIndex: "Dashboard",
                                    }
                                    handleScreenChangeClick(toSend)
                                }}>Cancel</Button>
                                <Button onClick={()=>{handleEmailSubscribe(
                                    {
                                        fullName: name,
                                        companyName: company,
                                        note: note,
                                        email: email,
                                
                                    }
                                )}} >Send</Button>
                            </Stack>

                        </Stack>

                    </Stack>
                </div>

            </div>
        </>
    )

}

export default Contact