import React, { useEffect, useState, useRef } from 'react'

import { Stack, Typography, Box, Backdrop, CircularProgress, Button } from '@mui/material';

import useWindowDimensions from './useWindowDimensions';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const MySurveys = ({
    mySurveys,
    getAllMySurveys,
    setShowMessages,
    handleScreenChangeClick,
}) => {
    const { height, width } = useWindowDimensions();
    const [feedIndex, setFeedIndex] = useState(0);
    const [showProgress, setShowProgress] = useState(false)



    useEffect(() => {
        console.log("in MySurveys", mySurveys)
        window.scrollTo(0, 0)

    }, [])

    useEffect(() => {
        if (mySurveys) {
            if (mySurveys.length === 0) {
                setShowMessages({
                    alertType: "info",
                    title: `No Surveys Found`,
                    message: "To get started, click on the menu icon to start a survey.",
                })
                handleScreenChangeClick({
                    selectedIndex: "HomePage"
                })
            }

        } 

    }, [mySurveys])




    return (
        <div style={{
            backgroundColor: "#212325",
            minHeight: 1024,
            backgroundAttachment: "fixed",
            alignItems: "center"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress size={50} style={{ color: '#ffffff' }} />
            </Backdrop>



            <Box
                paddingY={2.5}

                sx={{
                    width: "100%",
                    overflowY: "scroll",
                }}>
                <Stack
                    direction={"row"}
                    paddingX={window.$isMobile ? 2.5 : 5}
                    paddingTop={2}
                    // alignItems={"flex-end"}
                    justifyContent={"flex-start"}>
                    <ArrowBackIcon
                        onClick={() => {
                            console.log("HomePage")
                            handleScreenChangeClick({
                                selectedIndex: "HomePage"
                            })
                        }}
                        sx={{
                            fontSize: window.$isMobile ? 18 : 18, cursor: "pointer", color: "#fcfdfd",
                            opacity: 0.3
                        }} />


                </Stack>

                <Stack spacing={3}
                    paddingX={window.$isMobile ? 1 : 5}
                    width={"100%"}
                    alignItems={"center"}
                    direction={"column"}

                >
                    {/* <Typography
                        variant={window.$isMobile ? 'h5' : 'h3'}
                        paddingY={window.$isMobile ? 0 : 3}
                        paddingX={window.$isMobile ? 5 : 0}
                        sx={{
                            textAlign: "center",
                            textTransform: "none",
                            typography: { fontFamily: "Inter", fontWeight: 200 },
                            color: "#ffffff7f"
                        }} >
                        My StorySnacks
                    </Typography> */}



                    {mySurveys && <InfiniteScroll
                        dataLength={mySurveys.length} //This is important field to render the next data
                        next={() => {
                            getAllMySurveys(feedIndex + 1)
                            setFeedIndex(feedIndex + 1)
                        }}
                        hasMore={true}
                        loader={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }} >

                            </Typography>

                        }
                        refreshFunction={() => {
                            console.log("in refresh")
                            getAllMySurveys(0)
                        }}
                        pullDownToRefresh
                        pullDownToRefreshThreshold={40}
                        pullDownToRefreshContent={
                            <Typography
                                paddingY={3}
                                variant='h5'
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Pull down to refresh
                            </Typography>

                        }
                        releaseToRefreshContent={
                            <Typography
                                variant='h5'
                                paddingY={3}
                                sx={{
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    color: "#ffffff7f"
                                }}  >
                                Release to refresh
                            </Typography>
                        }
                    >
                        <Stack direction={"column"} spacing={5}
                            marginTop={2}
                            // useFlexGap flexWrap="wrap"
                            justifyContent={"flex-start"} width={"100%"}>
                            {mySurveys && mySurveys.map((e, index) => (
                                <Button 
                                key={e.surveyId}
                                onClick={() => {
                                    handleScreenChangeClick({
                                        selectedIndex: `SurveyView`,
                                        surveyCard: e

                                    })
                                }}>
                                    <Stack key={e.survey.surveyId} width={"100%"} direction={"row"} spacing={window.$isMobile ? 2 : 5}
                                        alignItems={"center"}
                                        justifyContent={"flex-start"}>
                                        <img

                                            src={e.survey.thumbnailUrl} alt="Location Image"
                                            style={{ width: "100%", objectFit: "cover", height: window.$isMobile ? 75 : 150, width: window.$isMobile ? 75 : 150, borderRadius: 10 }} />
                                        <Stack direction={"column"} paddingRight={window.$isMobile ? 0 : 40}>
                                            <Typography
                                                variant={window.$isMobile ? 'body1' : 'body1'}
                                                sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 2,
                                                    textTransform: "none",
                                                    textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 500 },
                                                    lineHeight: '100%', color: "#ffffff"
                                                }} fontStyle={"normal"}>
                                                {e.survey.title}
                                            </Typography>

                                            <Typography
                                                variant={window.$isMobile ? 'body2' : 'body1'}
                                                paddingTop={1}
                                                sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 2,
                                                    textTransform: "none",
                                                    textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 400 },
                                                    lineHeight: '100%', color: "#ffffff"
                                                }} fontStyle={"normal"}>
                                                {e.survey.subtitle}
                                            </Typography>
                                            {!window.$isMobile && <Typography
                                                variant={window.$isMobile ? 'body2' : 'body1'}
                                                paddingTop={2}


                                                sx={{
                                                    display: '-webkit-box',
                                                    overflow: 'hidden',
                                                    WebkitBoxOrient: 'vertical',
                                                    WebkitLineClamp: 5,
                                                    textTransform: "none",
                                                    whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                                                    textAlign: "left", typography: { fontFamily: "Inter", fontWeight: 400 },
                                                    lineHeight: '100%', color: "#ebebeb"
                                                }} fontStyle={"normal"}>
                                                {e.survey.description}
                                            </Typography>}




                                        </Stack>

                                    </Stack>
                                </Button>
                            ))}

                        </Stack>
                    </InfiniteScroll>
                    }
                </Stack>


            </Box>

        </div >
    )

}

export default MySurveys

