import React, { useEffect, useState,} from 'react'

import { Button, Stack, Typography, Box, Backdrop, CircularProgress, } from '@mui/material';

import useWindowDimensions from './useWindowDimensions';
import Footer from '../Components/Footer';
import SignUp from '../Components/SignUp'
import ServerBotView from './ServerBotView';


const HomePage = ({
    openLogin,
    surveyData,
    clones,
    setShowMessages,
    handleEmailSubscribe,
    handleScreenChangeClick,
    isAuthenticated,

}) => {
    const { height, width } = useWindowDimensions();
    const [showProgress, setShowProgress] = useState(false)
    const [recentSnack, setRecentSnack] = useState("Identifying and leveraging new high-engagement marketing channels to maximize customer reach and brand interaction.")

   

    useEffect(() => {
        // console.log("in HomePage", window.$timeSinceLastLoginMins)
        window.scrollTo(0, 0)
        // if (window.$timeSinceLastLoginMins < 5 && !isAuthenticated) {
        //     setShowMessages({
        //         alertType: "info",
        //         title: <Typography
        //             variant={'h6'}

        //             sx={{

        //                 textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 700 : 700 },
        //                 lineHeight: '120%', color: "#006ec2"
        //             }} fontStyle={"normal"}>
        //             Introducing AI-Powered Crisis Management with Digital Clones

        //         </Typography>,
        //         // message: `Create Magical Animated Tales with Your Own Voice for Your Little Ones.

        //         // Free While in Beta`,
        //         message: <Typography
        //             variant={'h7'}

        //             sx={{

        //                 textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 500 : 500 },
        //                 lineHeight: '120%', color: "#006ec2"
        //             }} fontStyle={"normal"}>

        //             Whether you need to anticipate public reactions during a crisis, swiftly test your response strategies, or optimize your messaging, our AI-driven personas—designed to reflect the distinct behaviors and preferences of your target audiences—can help you proactively manage crises and gain deeper insights.
        //             <br /><br />
        //             <b>Try it Free While in Beta!</b>
        //         </Typography>
        //     })
        // }
    }, [])



    return (
        <div style={{
            backgroundColor: "#fefefe",
            minHeight: 1024,
            backgroundAttachment: "fixed",
            alignItems: "center"
        }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={showProgress}

            >
                <CircularProgress size={50} style={{ color: '#ffffff' }} />
            </Backdrop>

            <Box
                paddingTop={5}


                sx={{
                    width: "100%",
                    minHeight: 1024,
                    overflowY: "scroll",
                }}>
                {/* add in not !isAuthenticated */}
                <Stack marginTop={window.$isMobile ? 0 : 4}
                    marginBottom={8}>
                    <SignUp
                       openLogin={openLogin}
                        width={width}
                        clones={clones}
                        handleEmailSubscribe={handleEmailSubscribe}
                      
                       
                    />

                </Stack>

                <Stack width={window.$isMobile ? "100%" : "100%"}
                    marginTop={window.$isMobile ? 5 : 1}
                    marginBottom={window.$isMobile ? 10 : 10}
                    paddingX={window.$isMobile ? 5 : 0}
                    justifyContent={"center"} direction={"row"}>
                    <Button
                        variant="contained"

                        onClick={() => {
                            handleScreenChangeClick({
                                selectedIndex: `Contact`,
                               
                            })
                        }}

                        sx={{
                            backgroundColor: "#e8454f",
                            padding: 2,
                            borderRadius: 7.5,
                            // width: window.$isMobile ? "100%" : "75%"
                        }}

                    >
                        <Typography
                            variant={'h6'}
                            sx={{
                                paddingX: 5,
                                textTransform: "none",
                                typography: { fontFamily: "Inter", fontWeight: 700 },

                                color: "#ffffff"
                            }} >
                            Request a Demo

                        </Typography>
                    </Button>
                </Stack>



                <Box sx={{ backgroundColor: "#000000" }}>
                    <Stack paddingX={window.$isMobile ? 3 : 0}>
                        <Typography
                            paddingTop={10}
                            paddingX={window.$isMobile ? 0 : 25}
                            paddingBottom={1}
                            variant={'h3'}
                            sx={{

                                textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                                lineHeight: '100%', color: "#ffffff"
                            }} fontStyle={"normal"}>
                            Engage with AI Persona Panels
                        </Typography>

                        <Typography

                            paddingBottom={0}
                            paddingX={window.$isMobile ? 0 : 15}
                            variant={window.$isMobile ? 'h7' : 'h6'}
                            sx={{

                                textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 400 },
                                lineHeight: '100%', color: "#ffffff"
                            }} fontStyle={"normal"}>
                            Discover Real-Time Feedback: Explore Insights and Opinions from Our AI-Driven Digital Clones
                        </Typography>
                      
                    </Stack>


                    {surveyData && <Stack sx={{

                        marginX: window.$isMobile ? 2.5 : 10,
                        marginTop: 0,
                        paddingBottom: 0

                    }}>
                        <ServerBotView
                            serverBotData={surveyData}
                            setShowMessages={setShowMessages}
                            handleScreenChangeClick={handleScreenChangeClick}
                        />
                    </Stack>}
                </Box>


                <Box sx={{ backgroundColor: "#f9f5ee" }}>
                    <Stack direction={"column"} alignItems={"center"} spacing={2}
                        paddingTop={window.$isMobile ? 10 : 10}
                        paddingBottom={window.$isMobile ? 10 : 15}
                        paddingLeft={"5%"}
                        paddingRight={"5%"}
                    >

                        <Typography paddingBottom={4}
                            paddingLeft={window.$isMobile ? "0%" : "20%"}
                            paddingRight={window.$isMobile ? "0%" : "20%"}
                            paddingTop={window.$isMobile ? "0%" : "2.5%"}
                            variant={window.$isMobile ? 'h4' : 'h2'}
                            sx={{

                                typography: { fontFamily: "Inter", fontWeight: 400 },
                                textAlign: "center",
                                lineHeight: '105%', color: "#151515"
                            }} >
                            Unlock Deeper Understanding with AI-Powered Personas
                        </Typography>
                        <Stack width={window.$isMobile ? "100%" : "100%"}
                            marginTop={window.$isMobile ? 5 : 1}
                            marginBottom={window.$isMobile ? 5 : 5}
                            paddingX={window.$isMobile ? 5 : 0}
                            justifyContent={"center"} direction={"row"}>
                            <Button
                                variant="contained"

                                onClick={openLogin}

                                sx={{
                                    backgroundColor: "#e8454f",
                                    padding: 2,
                                    borderRadius: 7.5,
                                    // width: window.$isMobile ? "100%" : "75%"
                                }}

                            >
                                <Typography
                                    variant={'h6'}
                                    sx={{
                                        paddingX: 5,
                                        textTransform: "none",
                                        typography: { fontFamily: "Inter", fontWeight: 700 },

                                        color: "#ffffff"
                                    }} >
                                    Try for Free

                                </Typography>
                            </Button>
                        </Stack>

                    </Stack>

                </Box>


            </Box>
            <Footer handleScreenChangeClick={handleScreenChangeClick} backgroundColor={"#000000"} />

        </div >
    )

}

export default HomePage

