import React, { useState, useEffect, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { TextField, InputAdornment, IconButton, Avatar, Button, Paper, Stack, Typography, Box, Link, Fab } from '@mui/material';
import hero from '../assets/homepage/hm-laptop.png'
import ReactPlayer from 'react-player'


import SendIcon from '@mui/icons-material/Send'
import AvatarPopUp from './AvatarPopUp';


const SignUp = ({
    clones,
    handleEmailSubscribe,
    width,
    openLogin
}) => {


    const [userInput, setUserInput] = useState("Sign Up for a Demo")
    const [showPopUp, setShowPopUp] = useState(false)
    const [cloneIndex, setCloneIndex] = useState(null)

    const handleEmailSignup = () => {
        handleEmailSubscribe({
            fullName: "Website visitor",
            companyName: "n/a",
            note: "Requesting a demo",
            email: userInput,
        })
        setUserInput("Sign Up for a Demo")

    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            // Do something with the message
            console.log("enter was pressed");
            handleEmailSignup()
        }
    };



    return (
        <>
            <Stack width={"100%"} direction={window.$isMobile ? "column" : "row"}
                justifyContent={"space-between"} alignItems={"center"}

                style={{

                    paddingLeft: "5%",
                    paddingRight: "5%",


                }}
            >

                <Stack width={"100%"} direction={"column"} alignItems={"flex-start"}>


                    <Typography
                        variant={window.$isMobile ? 'h3' : 'h3'}
                        paddingBottom={window.$isMobile ? 4 : 4}
                        paddingRight={window.$isMobile ? 0 : 10}

                        sx={{

                            textAlign: "left", typography: {
                                fontFamily: "Inter",
                                fontWeight: window.$isMobile ? 900 : 900
                            },
                            lineHeight: '105%', color: "#151515"
                        }} fontStyle={"normal"}>

                        {/* Rapidly Test Your Hypotheses with <span style={{ color: '#66a8da', fontWeight: 800 }}>AI-Driven Personas </span> */}
                        Proactive Crisis Management with AI-Driven Personas


                    </Typography>

                    <Typography
                        variant={window.$isMobile ? 'body1' : 'h7'}
                        paddingBottom={window.$isMobile ? 4 : 4}
                        paddingRight={window.$isMobile ? 0 : 15}

                        sx={{

                            textAlign: "left", typography: { fontFamily: "Inter", fontWeight: window.$isMobile ? 400 : 400 },
                            lineHeight: '120%', color: "#333333"
                        }} fontStyle={"normal"}>
                        DataSnack empowers you to stay ahead of crises by predicting public reactions using lifelike AI personas. Gain detailed insights into audience perceptions, enabling you to swiftly craft effective communication strategies and safeguard your brand's reputation with data-driven precision.

                    </Typography>

                    {window.$isMobile && <Stack>
                        <img src={hero} style={{ width: "100%", height: "auto" }} />
                    </Stack>}

                    <Stack marginTop={window.$isMobile ? 3 : 0} marginBottom={5}
                        direction={"column"}
                        width={"100%"}
                        justifySelf={"center"}
                        alignItems={window.$isMobile ? "center" : "flex-start"}>

                        <TextField

                            size={window.$isMobile ? "small" : "medium"}
                            focused={false}
                            variant={"outlined"}
                            margin={"none"}

                            onFocus={() => {
                                setUserInput("")


                            }}
                            sx={{
                                minWidth: window.$isMobile ? width * .90 : width * .35,
                                '& .MuiInputLabel-root': {
                                    color: '#006ec2', // Change the color to your desired value
                                },

                                '& .MuiInputBase-root': {
                                    color: "#2123257f",
                                    borderRadius: 7.5,
                                    typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 16,
                                    '& .MuiOutlinedInput-root': {
                                        color: "#212325",

                                    }
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#66a8da",
                                    borderWidth: "2px"
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: "#006ec2",
                                        borderWidth: "2px"
                                    }
                                }


                            }}

                            id="searchInput"
                            type={"email"}
                            onChange={(e) => {

                                setUserInput(e.target.value)
                            }}
                            onKeyDown={handleKeyDown}

                            value={userInput}
                            InputProps={{

                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="submit"



                                            onClick={handleEmailSignup}
                                            edge="end"
                                        >
                                            <SendIcon sx={{ fontSize: 15, color: "#006ec2", opacity: 0.8 }} />
                                        </IconButton>
                                    </InputAdornment>

                            }}

                            label={"Email"}

                        />

                    </Stack>
                    {/* <Stack width={window.$isMobile ? "100%" : "100%"}
                        marginTop={window.$isMobile ? 5 : 1}
                        marginBottom={window.$isMobile ? 5 : 5}
                        paddingX={window.$isMobile ? 5 : 0}
                        justifyContent={window.$isMobile ? "center" : "flex-start"} direction={"row"}>
                        <Button
                            variant="contained"
    fullWidth
                            onClick={openLogin}

                            sx={{
                                backgroundColor: "#e8454f",
                                padding: 2,
                                borderRadius: 7.5,
                                // width: window.$isMobile ? "100%" : "75%"
                            }}

                        >
                            <Typography
                                variant={'h6'}
                                sx={{
                                    paddingX: 5,
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 700 },

                                    color: "#ffffff"
                                }} >
                                Try for Free

                            </Typography>
                        </Button>
                    </Stack> */}
                </Stack>

                {!window.$isMobile && <Stack>
                    <img src={hero} style={{ width: "100%", height: "auto" }} />
                </Stack>}


            </Stack>


            <Typography
                variant={window.$isMobile ? 'h4' : 'h3'}
                paddingTop={window.$isMobile ? 5 : 10}
                paddingX={window.$isMobile ? 2.5 : 20}

                sx={{

                    textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 400 },
                    lineHeight: '100%', color: "#151515"
                }} fontStyle={"normal"}>
                Anticipate Audience Responses with Realistic AI Personas
            </Typography>

            <Stack
                paddingTop={4}
                paddingBottom={4}
            >


                <Stack
                    spacing={3}
                    marginBottom={4}

                    width={width}

                    sx={{
                        maxHeight: 200,
                        overflowX: 'auto',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },


                    }}
                    justifyContent={"flex-start"}
                    direction="row"
                    useFlexGap

                >


                    {clones && clones.map((e, i) => (
                        <Stack justifyContent={"center"}
                            key={e.digitalCloneId}
                            alignItems={"center"}
                            onClick={() => {
                                setShowPopUp(true)
                                setCloneIndex(i)
                            }}
                            paddingLeft={2}
                            sx={{ cursor: "pointer" }}
                            maxWidth={window.$isMobile ? 100 : 150}>
                            <Avatar



                                alt="BJM"
                                src={e.thumbnailUrl}

                                sx={{
                                    width: window.$isMobile ? 100 : 125,
                                    height: window.$isMobile ? 100 : 125,
                                    borderRadius: 5,
                                }}
                            />
                            <Typography

                                paddingTop={1}
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 1,
                                    whiteSpace: 'pre-wrap', wordWrap: 'break-word',
                                    fontSize: 12,
                                    textAlign: "center",
                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 500 },
                                    opacity: 0.8,
                                    color: "#212325"
                                }} >
                                {e.name}

                            </Typography>
                            {showPopUp && i == cloneIndex && <AvatarPopUp clone={e} />}
                        </Stack>
                    ))}
                </Stack>


            </Stack>

            <Stack width={"100%"}
                marginTop={window.$isMobile ? 2 : 5}
                alignItems={"center"}>
                <Typography
                    variant={window.$isMobile ? 'h4' : 'h3'}
                    paddingTop={0}
                    paddingBottom={2}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 400 },
                        lineHeight: '100%', color: "#151515"
                    }} fontStyle={"normal"}>
                    Learn More
                </Typography>


                <ReactPlayer
                    controls={true}
                    playbackRate={1.0}
                    width={window.$isMobile ? "95%" : "70%"}
                    height={"100%"}
                    url={"https://storage.googleapis.com/datasnack-videos/snackMedia-fed0b14b-d475-4196-a6ce-36c3f2cffcd0.mp4"}
                    onError={(e) => {
                        console.log("player error:", e)

                    }}
                    config={{
                        file: {
                            attributes: {
                                crossOrigin: "true",
                            },
                            tracks: [
                                {
                                    kind: 'subtitles',
                                    src: "https://storage.googleapis.com/datasnack-videos/snackMedia-fed0b14b-d475-4196-a6ce-36c3f2cffcd0.vtt", // URL to your subtitle file
                                    srcLang: 'en',
                                    label: 'English',
                                    default: true // Set as default if needed
                                },

                            ]
                        }
                    }}

                />

            </Stack>



        </>
    )

}

export default SignUp



