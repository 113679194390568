import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { Chip, Typography, Stack, InputAdornment, IconButton, TextField, Button, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import SendIcon from '@mui/icons-material/Send'
import useWindowDimensions from './useWindowDimensions';
import Typewriter from '../Components/Typewriter';
import { v4 as uuidv4 } from 'uuid';
import AvatarPopUp from '../Components/AvatarPopUp';


const ServerBotView = ({
    serverBotData,
    setShowMessages,
}) => {
    const scrollRef = useRef(null);
    const inputRef = useRef(null)
    let workInterval = useRef(null)
  




    const [updateLine, setUpdateLine] = useState(0)
    const [activeChip, setActiveChip] = useState(null)
    const [userInput, setUserInput] = useState("")
    const [shouldClearText, setShouldClearText] = useState(false)
    const { height, width } = useWindowDimensions();
    const [chatMessages, setChatMessages] = useState(null)
    const [digitalCloneId, setDigitalCloneId] = useState(null)
    
    const [foundElement, setFoundElement] = useState(null)


    const animateWorking = (setUserInput) => {
        // console.log("in animate working...")
        let globalIndex = 0
        const eWorking = `g...`
        let index = 0
        let textAccumulator = "workin"

        workInterval.current = setInterval(() => {
            console.log("current work interval", workInterval.current)
            textAccumulator = textAccumulator + eWorking[index];
            setUserInput(textAccumulator);
            index++
            globalIndex++
            if (index === eWorking.length) {
                index = 0
                textAccumulator = "workin"
            }
            // failsafe in case something weird happens
            if (globalIndex > 25) {
                clearInterval(workInterval.current)
            }

        }, 750)
    }


    // handle scroll to next line effect
    useEffect(() => {
        // console.log("updateLine", updateLine)
        if (updateLine === 0) {
            clearInterval(workInterval.current)
            setUserInput("")
            console.log("swap waiting for typewriter")
            return
        }


        if (scrollRef.current) {

            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }

        if (updateLine === -1) {
            clearInterval(workInterval.current)
            setUserInput("")
            console.log("content stopped")


        }
    }, [updateLine])




    useEffect(() => {
        if (serverBotData === null || serverBotData === undefined) {
            return
        }
        console.log("serverBotData", serverBotData)
        setChatMessages(serverBotData.messages)
        setActiveChip("DataSnack")
        setDigitalCloneId("DataSnack")



        clearInterval(workInterval.current)
        setUserInput("")


        return () => {
            clearInterval(workInterval.current)
        }
    }, [serverBotData]);



    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            // Do something with the message
            console.log("enter was pressed");
            handleAskBot()
        }
    };

    const handleAskBot = () => {


        console.log("submit...")
        setShouldClearText(true)
        if (userInput === "") {
            return
        }
        const clientInput = userInput


        handleChatInput(clientInput)

        animateWorking(setUserInput)


    }

    useEffect(() => {
        if (activeChip) {
            if (activeChip === "DataSnack") {
                return
            }
            let temp = [...chatMessages];

            let foundElement = serverBotData.clones.find(element => element.digitalCloneId === activeChip);
            console.log("found element", foundElement)
            setFoundElement(foundElement)


            temp.push({
                digitalCloneId: activeChip,
                surveyId: serverBotData.surveyId,
                messageId: uuidv4(),
                message: foundElement.results,
                messageSender: "assistant",
            })

            console.log("pre-server message array w/ user query", temp)

            // update screen w/ user input
            setChatMessages(temp)

        }

    }, [activeChip])

    useEffect(() => {
        if (chatMessages) {
            let lastMessage = chatMessages[chatMessages.length - 1]
            if (lastMessage.messageSender !== "user") {
                console.log("assistant msg received")
                if (scrollRef.current && chatMessages.length !== 1) {
                    console.log("scrollRef.current && chatMessages.length !== 1 => called")
                    scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                return
            }
            axios.post('/api/v1/handleChat', chatMessages)
                .then(response => {
                    let temp = [...chatMessages];
                    temp.push(response.data)
                    setChatMessages(temp)
                    console.log("with data from server:", temp)

                }).catch(error => {
                    // need to figure out way to handle user swiping away from screen and waiting
                    console.log(error)
                    setShowMessages({
                        alertType: "error",
                        title: "Problem with game response",
                        message: "Please try again later",
                    })
                })
        }

    }, [chatMessages])

    const handleChatInput = (messageInput) => {
        if (messageInput === "") {
            return
        }
        // make sure it's in play game screen

        let temp = [...chatMessages];

        temp.push({
            digitalCloneId: activeChip,
            surveyId: serverBotData.surveyId,
            messageId: uuidv4(),
            message: messageInput,
            messageSender: "user",
        })

        console.log("pre-server message array w/ user query", temp)

        // update screen w/ user input
        setChatMessages(temp)
    }


    return (
        <div style={{
            height: height * .935

        }}>
            <Stack
                // height={height * .93}
                height={"100%"}
                direction={"column"} alignContent={"flex-start"} justifyContent={"space-between"}>
                <Typography
                    variant={'h6'}
                    paddingTop={4}
                    paddingBottom={2}
                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '100%', color: "#ffffff"
                    }} fontStyle={"normal"}>
                    Digital Personas
                </Typography>
                <Stack spacing={2}
                    paddingTop={3}
                    marginBottom={4}
                    // marginBottom={window.$isMobile ? 0 : 4}
                    width={"100%"}
                    sx={{ maxHeight: 600, overflowX: 'scroll' }}
                    justifyContent={"center"}
                    direction="row"
                    useFlexGap flexWrap="wrap">
                    <Chip
                        variant={'filled'}

                        label={"DataSnack"}



                        sx={{
                            backgroundColor: activeChip === "DataSnack" ? '#66a8da' : '#2d2d2d',
                            padding: 1,
                            fontSize: 16,
                            maxWidth: width * .8,
                            typography: { fontFamily: "Inter" },
                            textTransform: "none", color: "#ffffff",

                        }}
                        onClick={() => {
                            console.log("clicked chip - DataSnack")
                            setActiveChip("DataSnack")


                        }} />
                    {serverBotData && serverBotData.clones.map((e, i) => (
                        <Chip
                            variant={'filled'}
                            key={e.digitalClone.digitalCloneId}
                            label={e.digitalClone.name}



                            sx={{
                                backgroundColor: activeChip === e.digitalClone.digitalCloneId ? '#66a8da' : '#2d2d2d',
                                padding: 1,
                                fontSize: 16,
                                maxWidth: width * .8,
                                typography: { fontFamily: "Inter" },
                                textTransform: "none", color: "#ffffff",

                            }}
                            onClick={() => {
                                console.log("clicked chip", e.digitalClone.digitalCloneId)
                                setActiveChip(e.digitalClone.digitalCloneId)


                            }} />
                    ))}
                    
                     {foundElement && activeChip == foundElement.digitalCloneId && <AvatarPopUp clone={foundElement.digitalClone} />}

                </Stack>


                <Box overflow="scroll" height={height * .80} sx={{
                    // backgroundColor: "#f4f5f6",
                    backgroundColor: "#333333",
                    borderRadius: 1,
                }}>

                    <Stack
                        paddingLeft={"5%"}
                        paddingRight={"5%"}
                        paddingTop={"5%"}
                        paddingBottom={"2.5%"}
                        alignItems={"flex-start"}
                        spacing={1}
                        direction={"column"} >

                        {chatMessages && chatMessages.map((e, i) => (
                            <Stack key={e.messageId} marginBottom={5}>

                                {/* initial content */}
                                {i === 0 && <Typography


                                    marginBottom={2}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textAlign: "left", typography: { fontFamily: "Inter" },
                                        fontSize: 16, lineHeight: '150%', color: "#cccccc"
                                    }} fontStyle={"normal"}>
                                    <span style={{ fontWeight: 800 }}>Analysis<br /><br /></span>{e.message}

                                </Typography>}

                                {/* initial content */}
                                {e.message.constructor === Array &&

                                    <Stack>
                                        <Typography

                                          
                                            marginBottom={2}
                                            sx={{
                                                whiteSpace: "pre-wrap",
                                                wordBreak: "break-word",
                                                textAlign: "left", typography: { fontFamily: "Inter" },
                                                fontSize: 20, lineHeight: '150%', color: "#66a8da"
                                            }} fontStyle={"normal"}>
                                            <span style={{ fontWeight: 800 }}>{foundElement.digitalClone.name}</span>
                                            

                                        </Typography>
                                        {e.message.map((v) => (
                                            <Typography

                                                key={v.id}
                                                marginBottom={2}
                                                sx={{
                                                    whiteSpace: "pre-wrap",
                                                    wordBreak: "break-word",
                                                    textAlign: "left", typography: { fontFamily: "Inter" },
                                                    fontSize: 16, lineHeight: '150%', color: "#cccccc"
                                                }} fontStyle={"normal"}>
                                                <span style={{ fontWeight: 800 }}>Question: </span>{v.question}
                                                <br />
                                                <span style={{ fontWeight: 800 }}>Answer: </span>{v.answer}

                                            </Typography>
                                        ))}

                                    </Stack>
                                }




                                {/* user response */}
                                {e.messageSender === "user" && i !== 0 && <Typography


                                    marginBottom={2}
                                    sx={{
                                        whiteSpace: "pre-wrap",
                                        wordBreak: "break-word",
                                        textAlign: "left", typography: { fontFamily: "Inter" },
                                        fontSize: 16, lineHeight: '150%', color: "#cccccc"
                                    }} fontStyle={"normal"}>
                                    <span style={{ fontWeight: 800 }}>User<br /><br /></span>{e.message}

                                </Typography>}

                                {/* assistant - instructions - questions */}
                                {e.messageSender !== "user" && i !== 0 && e.message.constructor !== Array &&
                                    <Stack>
                                        <Typography


                                            marginBottom={2}
                                            sx={{
                                                whiteSpace: "pre-wrap",
                                                wordBreak: "break-word",
                                                fontWeight: 800,
                                                textAlign: "left", typography: { fontFamily: "Inter" },
                                                fontSize: 16, lineHeight: '150%', color: "#cccccc"
                                            }} fontStyle={"normal"}>
                                            DataSnack

                                        </Typography>
                                        <Typewriter origText={e.message} textColor={"#cccccc"} setUpdateLine={setUpdateLine} />
                                    </Stack>
                                }

                            </Stack>
                        ))}


                        <Typography ref={scrollRef} />



                    </Stack>

                </Box>


                <Stack marginTop={4} marginBottom={5} direction="column" justifySelf={"flex-end"} alignItems={"center"}>

                    <TextField
                        ref={inputRef}
                        size={"small"}
                        focused={true}
                        variant={"outlined"}
                        margin={"none"}
                        onFocus={() => {

                            if (shouldClearText) {
                                setUserInput("")
                                setShouldClearText(false)

                            }
                        }}
                        sx={{
                            width: window.$isMobile ? "325px" : "500px",
                            '& .MuiInputLabel-root': {
                                color: '#ffffff', // Change the color to your desired value
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: "#ffffff"
                            },
                            '& .MuiInputBase-root': {
                                color: "#ffffff7f",
                                borderRadius: 1,
                                typography: { fontFamily: "Roboto", fontWeight: 400 }, fontSize: 16,
                                '& .MuiOutlinedInput-root': {
                                    color: "#ffffff",

                                }
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: "#ffffff7f",
                                borderWidth: "1px"
                            },
                            "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                    borderColor: "#ffffff7f",
                                    borderWidth: "1px"
                                }
                            }


                        }}

                        id="searchInput"
                        type={"text"}
                        onChange={(e) => {

                            setUserInput(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}

                        value={userInput}
                        InputProps={{


                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="submit"



                                        onClick={handleAskBot}
                                        edge="end"
                                    >
                                        <SendIcon sx={{ fontSize: 15, color: "#ffffff", opacity: 0.8 }} />
                                    </IconButton>
                                </InputAdornment>

                        }}

                        label={"Chat"}

                    />

                </Stack>


            </Stack>
        </div>
    )

}

export default ServerBotView







