// axiosConfig.js
import axios from 'axios';
import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';

if (process.env.NODE_ENV === 'development') {
  window.$ip = 'http://localhost:8080'
 

}

if (process.env.NODE_ENV === 'production') {
  window.$ip = 'https://datasnack.uc.r.appspot.com'
  // window.$ip = 'https://c42e-24-5-163-248.ngrok-free.app'
  
}

const instance = axios.create({
  baseURL: window.$ip,
});



instance.interceptors.request.use(async (config) => {
  try {
    if (new Date().getTime() > window.$tokenRefreshTime) {
      const currentUser = firebase.auth().currentUser
      const token = await currentUser.getIdToken(true)
      // console.log("Got new token", new Date())
      localStorage.setItem('token', token)
      console.log("current user for axios", currentUser)
      config.headers.Authorization = `Bearer ${token}`;
      
    
      return config;
  
    } else {
      let token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
       
        return config;
      } else {
        console.log("problem getting token")
        return config;
      }
  
    }
  } catch(error) {
    console.log("axios interceptor error:", error)
    let token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      } else {
        console.log("problem getting token")
        return config;
      }
  }
  
});


export default instance

