import React, { useEffect, useState, useRef } from 'react'
import axios from '../Login/axiosConfig'
import { keyframes } from '@mui/system';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { Stack, Typography, Button, TextField } from '@mui/material';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';

const pulsate = keyframes`  
  0% {  
    transform: scale(1);  
    opacity: 1;  
  }  
  50% {  
    transform: scale(1.25);  
    opacity: 0.8;  
  }  
  100% {  
    transform: scale(1);  
    opacity: 1;  
  }  
`;


const CreateVoice = ({
    isAuthenticated,
    openLogin,
    setShowMessages,
    getCurrentUser,


    setIsCustomVoice,
    setShowCreateVoice,

    handleScreenChangeClick,
    styleType,
    checkForProcessingMedia,



}) => {


    const clientRef = useRef(null)
    const wavRecorderRef = useRef(null)
    const wavStreamPlayerRef = useRef(null)
    const [recordClicked, setRecordClicked] = useState(false)
    const startTimeRef = useRef(new Date().toISOString());
    const [sessionCreatedSuccess, setSessionCreatedSuccess] = useState(false)
    const [statusMsg, setStatusMsg] = useState("")
    const [snackId, setSnackId] = useState(null)
    const checkIndex = useRef(0)
    const [transcript, setTranscript] = useState("")
    const audioId = useRef("")
    const [voiceName, setVoiceName] = useState("")





    const handleRecordButton = () => {
        if (!isAuthenticated) {
            setShowMessages({
                alertType: "error",
                title: "Setup a StorySnacks Account",
                message: `Please log in or create an account to continue.`,
            })
            openLogin()
            return
        }

        if (!voiceName) {
            setShowMessages({
                alertType: "error",
                title: "No Voice Name",
                message: `Please add voice name to continue.`,
            })
            return
        }

        switch (recordClicked) {
            case true:

                disconnectConversation()
                setRecordClicked(false)
                setIsCustomVoice(true)
                setShowCreateVoice(false)
               
                break;
            case false:
                startSession()
                setRecordClicked(true)
                checkIndex.current = 0
                // start session initiates use effect that triggers conversation to start
                break;
        }
    }

    // const createVoice = (voiceName) => {
    //     window.scrollTo(0, 0)
    //     setShowMessages({
    //         alertType: "success",
    //         title: "Creating Custom Voice...",
    //         timeInSecs: 150,
    //         showProgress: true,
    //         message: `Processing usually takes < 2 minute.`,
    //     })

    //     axios.post(`/api/v1/createVoice`, {
    //         voiceName: voiceName
    //     })
    //         .then(() => {

    //             getCurrentUser()
    //             setIsCustomVoice(true)
    //             setShowCreateVoice(false)


    //         }).catch(error => {
    //             // need to figure out way to handle user swiping away from screen and waiting
    //             console.log("getSnackCard error:", error)
    //             setShowMessages({
    //                 alertType: "error",
    //                 title: "Problem Creating Voice",
    //                 message: `Please try again later.`,
    //             })

    //         })
    // }



    useEffect(() => {

        if (sessionCreatedSuccess) {
            connectConversation()
        }

    }, [sessionCreatedSuccess])



    const startSession = () => {

        wavStreamPlayerRef.current = new WavStreamPlayer({ sampleRate: 24000 })
        let myToken = localStorage.getItem('token');
        //interview
        clientRef.current = new WebSocket(`${window.$ip}/api/v1/interview?token=${myToken}&voiceName=${voiceName}`);
        const client = clientRef.current
        console.log("client", client)


        client.onopen = () => {
            client.send(JSON.stringify({
                type: "intro",
                data: "Hi from the client"
            }));
        };


        client.onmessage = (async (e) => {
            console.log("raw e", e)


            const obj = JSON.parse(e.data)
            console.log("obj from server:", obj)
            switch (obj.type) {
                // assistant question
                case 'response.audio_transcript.done':
                    console.log("response.audio_transcript.done", obj);


                    break;

                case "response.content_part.done":
                    console.log("response.content_part.done", obj);
                    setTranscript(obj.content.transcript)


                case "snack.id":
                    console.log("snackId", obj);
                    if (obj.snackId) {
                        setSnackId(obj.snackId)
                    }
                    break;


                case 'session.created':
                    console.log("session.created", obj);
                    setSessionCreatedSuccess(true)
                    setStatusMsg("Session Created")
                    break;

                case 'input_audio_buffer.speech_started':

                    break;

                case 'response.audio.delta':
                    console.log("response.audio.delta:", obj);

                    if (obj.delta) {
                        if (obj.item_id) {
                            audioId.current = obj.item_id
                            console.log("audioId", audioId.current)
                        }
                        console.log("adding audio to buffer")
                        // const pcmData = WavPacker.floatTo16BitPCM(obj.delta)
                        const binary = atob(obj.delta);
                        const bytes = Uint8Array.from(binary, (c) => c.charCodeAt(0));
                        const pcmData = new Int16Array(bytes.buffer);
                        try {

                            wavStreamPlayerRef.current.add16BitPCM(pcmData);


                        } catch (error) {
                            console.log("wavStreamPlayer:", error)
                        }

                    }

                    break;

                default:
                    console.log("default:", obj)
            }

        })

        client.onerror = ((e) => {
            console.log("on error", e)
        })

        setStatusMsg("Starting...")
    }

    const connectConversation = async () => {

        const client = clientRef.current
        console.log("client", client)
        await wavStreamPlayerRef.current.connect();
        wavRecorderRef.current = new WavRecorder({ sampleRate: 24000 })
        const wavRecorder = wavRecorderRef.current


        // Set state variables
        startTimeRef.current = new Date().toISOString();

        // Connect to microphone
        await wavRecorder.begin();

        await wavRecorder.record((data) => {
            client.send(data.mono)


        });
        setStatusMsg("Recording")
        setTimeout(() => {
            setStatusMsg("Begin Speaking")
        }, [3 * 1000])


    };

    const disconnectConversation = async () => {

        setSessionCreatedSuccess(false)
        setStatusMsg("Disconnecting")
        const client = clientRef.current;
        client.send(JSON.stringify({
            type: "disconnect",
            data: "Disconnecting..."
        }));


        const wavRecorder = wavRecorderRef.current;
        try {
            await wavRecorder.end();
        } catch (error) {
            console.log("recorder stop error:", error)
            setRecordClicked(false)
        }


        const wavStreamPlayer = wavStreamPlayerRef.current;
        try {
            await wavStreamPlayer.interrupt();
        } catch (error) {
            console.log("player stop:", error)
            setRecordClicked(false)
        }


        setStatusMsg("")
        client.close()
      



    };

    return (

        <Stack width={"100%"} alignItems={"center"} >
            <Stack
                direction={'column'}
                spacing={3}
                width={window.$isMobile ? "100%" : "75%"}

                paddingTop={window.$isMobile ? 2 : 0}
                alignItems={"center"}
                justifyContent={window.$isMobile ? "center" : "flex-start"}
            // onClick={handleRecordButton}

            // sx={{ cursor: "pointer" }}
            // maxWidth={window.$isMobile ? 100 : 125}
            >

                <Typography
                    variant={'h7'}

                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 500 },
                        lineHeight: '120%', color: "#e5f0f8"
                    }} fontStyle={"normal"}>
                    To create your custom voice, press the <b>"Record Your Voice"</b> button and tell us a story. The DataSnack AI interviewer will ask you questions as you speak. For the best results, aim to record for 1 to 2 minutes. You can stop anytime after that.

                </Typography>


                <TextField

                    size={"small"}
                    focused
                    variant={'outlined'}
                    margin={"none"}
                    sx={textBoxDesign}
                    id="searchInput"
                    type={"text"}
                    onChange={(e) => {
                        setVoiceName(e.target.value)
                    }}
                    value={voiceName}
                    label={`Voice Name`}


                />

                <Typography
                    variant={window.$isMobile ? 'h6' : 'h5'}

                    sx={{

                        textAlign: "center", typography: { fontFamily: "Inter", fontWeight: 900 },
                        lineHeight: '120%', color: "#fcfdfd"
                    }} fontStyle={"normal"}>
                    {statusMsg}

                </Typography>

            </Stack>
            <Stack

                paddingTop={5}
                spacing={3}
                width={"100%"}
                direction={"row"}

                justifyContent={"center"}
            >

                <Stack width={"100%"}>
                    <Stack

                        // paddingTop={5}
                        spacing={3}
                        width={"100%"}
                        direction={"row"}

                        justifyContent={"center"}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setIsCustomVoice(false)
                                setShowCreateVoice(false)
                            }}

                            sx={{
                                borderColor: "#ffffff",
                                width: window.$isMobile ? "100%" : "75%"
                            }}

                        >
                            <Typography
                                variant={'body1'}
                                sx={{

                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 700 },

                                    color: "#ffffff"
                                }} >
                                Cancel

                            </Typography>
                        </Button>

                        <Button
                            variant="contained"
                            onClick={handleRecordButton}

                            sx={{
                                backgroundColor: "#e8454f",
                                width: window.$isMobile ? "100%" : "75%"
                            }}

                        >
                            <Typography
                                variant={'body1'}
                                sx={{

                                    textTransform: "none",
                                    typography: { fontFamily: "Inter", fontWeight: 700 },

                                    color: "#ffffff"
                                }} >
                                {recordClicked ? 'Stop' : 'Record Your Voice'}

                            </Typography>
                        </Button>



                    </Stack>

                </Stack>
            </Stack>



        </Stack>


    )

}



export default CreateVoice

const textBoxDesign = {
    width: "100%",
    '& label.Mui-focused': {
        color: '#ffffff',

    },
    '& .MuiFormLabel-root': {
        color: '#ffffff',
        opacity: 0.9,
    },
    '& .MuiInputBase-root': {
        color: "#ffffff7f",
        borderRadius: 1,
        typography: { fontFamily: "Inter", fontWeight: 400 }, fontSize: 16,
        '& .MuiOutlinedInput-root': {
            color: "#ffffff",

        }
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "#006ec2",
        borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "#006ec2",
            borderWidth: "1px"
        }
    }


}